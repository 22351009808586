import { apiInstance } from "./ApiInstance";

class EstudianteService {
    
    static async getEstudiante(id){
        try {
            const response = await apiInstance.get(`/estudiante/${id}`);
            if(response.data){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static actualizar(data){
        return apiInstance.patch(`/estudiante`, data);
    }

    static setDatosFacturacion(data){
        return apiInstance.patch(`/estudiante/facturacion`, data);
    }

    static actualizarDatosAcceso(data){
        return apiInstance.post(`/estudiante/cambiar/password`, data);
    }

    static solicitarRecuperacion(data){
        return apiInstance.post('/estudiante/recuperar/password', data);
    }
}

export { EstudianteService }