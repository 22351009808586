<template>
	<div class="layout-footer">
		<span class="footer-text-left">
			<small>&copy; 2023 - SIAC</small>
		</span>
		<span class="footer-text-right">
			<!-- <button class="p-link"><i class="pi pi-facebook"></i></button> -->			
			<small>v1.0.0</small>
		</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>
