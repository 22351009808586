import VueJwtDecode from 'vue-jwt-decode'

function isTokenExpire(exp) {
  let currentDate = new Date()
  let expiryDate = new Date(exp * 1000)
  return currentDate > expiryDate
}

function decodeJWT(token) {
  try {
    let value = VueJwtDecode.decode(token)
    return value
  } catch {
    return false
  }
}

export { isTokenExpire, decodeJWT }
