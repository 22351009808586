export const uppercaseDirective = {
    mounted(el) {
        el.addEventListener('input', () => {
            el.value = el.value.toUpperCase();
            el.dispatchEvent(new Event('input')); // Emite el evento 'input' para actualizar el v-model
        });
    }
};

export const lowercaseDirective = {
    mounted(el) {
        el.addEventListener('input', () => {
            el.value = el.value.toLowerCase();
            el.dispatchEvent(new Event('input')); // Emite el evento 'input' para actualizar el v-model
        });
    }
};

export const onlyNumbersDirective = {
    mounted(el) {
        el.addEventListener('input', () => {
            const regex = /[^0-9]/g;
            el.value = el.value.replace(regex, '');
            el.dispatchEvent(new Event('input'));
        });
    }
};

export const emailDirective = {
    mounted(el) {
        el.addEventListener('input', () => {
        let value = el.value;
        value = value.toLowerCase().replace(/\s+/g, '');
        el.value = value;
        el.dispatchEvent(new Event('input'));
        });
    }
};