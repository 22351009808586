import {createRouter, createWebHistory} from 'vue-router';
import { isAuthenticatedGuard } from './authGuard';

const routes= [
	{
        path: '/login',
        name: 'login',
        component: () => import('../modules/login/FormLogin.vue')
    },
	{
		path: '/',
		component: () => import('../components/Dashboard.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},


	// Rutas Públicas
	
	// ruta para acceder al formulario de publico de inscripcion
	// {	
	// 	path: '/inscripcion/programa/:id/:inscripcionId?', 
	// 	name: 'inscripcion-publico',
	// 	component: () => import('../modules/inscripcion/FormInscripcionPublico.vue'),
	// },

	{
        path: '/:pathMatch(.*)*',
        redirect: { name: 'login' }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
	scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;