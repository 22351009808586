<template>
	<div class="login-body">
		<!-- <div class="login-image"></div> -->
		<div class="card login-panel">
			<div class="login-panel-content">
				<div class="grid mt-4">
					<Card class="p-fluid col-12">						
						<template v-slot:content>
							<div class="col-12 px-5">
								<img src="/layout/images/cepi/logo-cepi-web-dark.png" class="img-responsive" alt="GADCH" style="width: 100%; height: auto;"/>
							</div>
							<div class="col-12">
								<h6 class="text-center">SIAC</h6>
								<!-- <span class="guest-sign-in">Iniciar Sesión</span> -->
							</div>
							<div class="col-12" style="text-align:left">
								<label class="login-label">Correo</label>
								<div class="login-input">
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon">
											<i class="pi pi-user"></i>
										</span>
										<InputText v-model="correo" type="text" placeholder="Correo" @input="validateField('correo')"/>								
									</div>							
									<small v-if="fieldErrors?.correo" class="p-error" >{{ fieldErrors.correo }}</small>
								</div>
							</div>
							<div class="col-12" style="text-align:left">
								<label class="login-label">Contraseña</label>
								<div class="login-input">
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon">
											<i class="pi pi-unlock"></i>
										</span>
										<Password v-model="password" placeholder="Contraseña" @input="validateField('password')" @keyup.enter="login" :feedback="false" toggleMask/>
									</div>
									<small v-if="fieldErrors?.password" class="p-error" >{{ fieldErrors.password }}</small>
								</div>						
							</div>
							<div class="col-12 md:col-12 button-pane">								
								<Button label="Iniciar Sesión" @click="login()" :loading="isLoading"/>								
							</div>
							<div class="col-12 md:col-12">
								<Divider layout="horizontal" align="center">
									<b>ó</b>
								</Divider>
							</div>
							<div class="col-12 md:col-12">
								<!-- <GoogleLogin :callback="callbackLoginGoogle" :error="errorLoginGoogle">
									
								</GoogleLogin>								 -->
								<Button
									@click="loginGoogle"
									class="p-button-outlined p-button-help flex justify-content-center">
									<svg style="width:18px;" class="mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
										viewBox="-0.5 0 48 48" version="1.1">
										<g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
											<g id="Color-" transform="translate(-401.000000, -860.000000)">
												<g id="Google" transform="translate(401.000000, 860.000000)">
													<path
														d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
														id="Fill-1" fill="#FBBC05"> </path>
													<path
														d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
														id="Fill-2" fill="#EB4335"> </path>
													<path
														d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
														id="Fill-3" fill="#34A853"> </path>
													<path
														d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
														id="Fill-4" fill="#4285F4"> </path>
												</g>
											</g>
										</g>
									</svg>
									<span>Iniciar Sesión con Google</span>
								</Button>
								<!-- <Button @click="logoutGoogle" class="btn btn-primary mt-4 ml-2 w-auto">Salir Google</Button>							 -->
							</div>
							<div class="grid mt-2">
								<div class="col-12 md:col-12">
									<Button class="p-button-text" @click="showModalRecuperar=!showModalRecuperar" label="Recuperar contraseña" />
								</div>
							</div>								
						</template>
					</Card>
					<div class="col-12">
						<small class="text-center">© 2023 SIAC - CEPI v1.0.0</small>
					</div>
				</div>
			</div>
		</div>
		<Toast />
		<ModalRecuperarPassword
			:visibleModal="showModalRecuperar"
			@close="cerrarModalRecuperar"
		/>
	</div>
</template>

<script>
	import { useAuthStore }	from '../../stores/authStore';
	import { AuthService } from '../../service/AuthService';
	import { loginSchema } from "./validations/loginValidate";
	
	import {
		decodeCredential, 
		googleTokenLogin,
		// googleAuthCodeLogin,
		// googleSdkLoaded,
		googleLogout,
	} from 'vue3-google-login';
	import ModalRecuperarPassword from "./modals/ModalRecuperarPassword.vue";

	export default {
		name: 'Login',
		components: {
			ModalRecuperarPassword
		},
		setup() {
			const authStore = useAuthStore();
			// const error = computed(() => authStore.error);
			// const isLoading = computed(() => authStore.isLoading);
			return {
				authStore,
			}
		},
		data() {
			return {
				correo: null,
				password: null,	
				isLoading: false,	
				
				showModalRecuperar: false,

				tokenGoogle: null,
				sessionGoogle: false,

				fieldErrors: [],
			}
		},
		mounted() {
			if(this.authStore.isTokenValid()){
				this.$router.push('/');			
			}
		},
		methods: {
			async validateField(field) {
				const loginData = {
					correo: this.correo,
					password: this.password,
					sessionGoogle: this.sessionGoogle
				};
				try {
					await loginSchema.validateAt(field, loginData);
					this.fieldErrors[field] = '';
				} catch (error) {
					this.fieldErrors[field] = error.message;
				}
			},
			async validateLogin(){
				let errorValidacion = false;
				const loginData = {
					correo: this.correo,
					password: this.password,
					sessionGoogle: this.sessionGoogle
				};
				await loginSchema
							.validate(loginData, { abortEarly: false })
							.then(() => {
								errorValidacion = false;
								this.fieldErrors = [];
							})
							.catch((errors) => {
								const formattedErrors = [];
								errors.inner.forEach((error) => {
									formattedErrors[error.path] = error.message;
								});
								this.fieldErrors = formattedErrors;
								errorValidacion = true;
							});
				return errorValidacion;
			},
			callbackLoginGoogle(){
				// console.log(response);
				// const userData = decodeCredential(response.code);
				// console.log("Handle the userData", userData);	
				googleTokenLogin().then((response) => {
					console.log("Handle the response", response);
					const userData = decodeCredential(response.access_token);
					console.log("Handle the userData", userData)
				});
			},
			errorLoginGoogle(response){
				console.log(response);
				googleLogout();
			},
			loginGoogle(){
				googleTokenLogin().then((response) => {
					console.log("Handle the response", response);
					// const userData = decodeCredential(response.credential)
					// console.log("Handle the userData", userData)
				});

				// googleAuthCodeLogin().then((response) => {
				// 	console.log("Handle the response", response);
				// 	const userData = decodeCredential(response.credential);
				// 	console.log("Handle the userData", userData);
				// });
				
				// googleSdkLoaded((google) => {
				// 	google.accounts.oauth2.initCodeClient({
				// 		client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
				// 		scope: 'email profile openid',
				// 		callback: (response) => {
				// 			console.log("Handle the response", response);
				// 			const userData = decodeCredential(response.credential);
				// 			console.log("Handle the userData", userData);
				// 		}
				// 	}).requestCode()
				// });
				
			},
			logoutGoogle(){
				googleLogout();
			},
			async login() {
				this.isLoading = true;	
				const errorValidacion = await this.validateLogin();			
				if(!errorValidacion){
					try {					
						const response = await AuthService.login(this.correo, this.password);
						// console.log(response.data)			

						if(response.data?.transaccion){
							this.$toast.add({severity:'success', summary: 'Mensaje', detail:'Iniciando sesión...', life: 3000});
							this.$router.push('/');
						}else{
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${response.data.mensaje}`, life: 3000});				
						}
						
					} catch (error) {
						// console.log(error.response);
						if(error.response?.data.message){
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${error.response?.data.message}`, life: 3000});				
						}else{
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: `Ocurrió un error al conectarse con el servidor. ${error.message}`, life: 3000});				
						}
						this.isLoading = false;
					}
				}
				
				this.isLoading = false;				
			},

			cerrarModalRecuperar(){
				this.showModalRecuperar = false;
			}
		},
	}
</script>

<style scoped>
.login-body .login-panel .login-panel-content {
	width: 380px;
	position: relative;
	margin-left: -190px;
	top: 30px;
	left: 50%;	
}
</style>
