import { defineStore } from 'pinia';
import router from '../router';

import { decode, encode, encodeObj, decodeObj } from '../helpers/encodeHelper';
import { decodeJWT, isTokenExpire } from '../helpers/tokenUtil';
// import { AuthService } from '../service/AuthService';

export const STORAGE_ID = 'auth-siace';

export const useAuthStore = defineStore(STORAGE_ID, {
    id: STORAGE_ID,
    state: () => {
        return {
            user: decodeObj(localStorage.getItem('user') || {}),
            token: decode(localStorage.getItem('token')),
            permisos: decodeObj(localStorage.getItem('permisos') ?? []),
            // roles: decodeObj(localStorage.getItem('roles') ?? []),  
            rol: decodeObj(localStorage.getItem('rol') ?? null),  
            isLogin: false,
            error: null,
            isLoading: false,                              
        }
    },
    getters: {
        getUser: (state) => {
            return state.user;
        },
        getToken: (state) => {
            return state.token;
        },
        getRol: (state) => {
            // if (state.rol) 
            //     return "No tiene Rol asignado";
            return state.rol;
        },
        getPermisos: (state) => {
            // if (state.permisos.length == 0) 
            //     return "No tiene Permisos asignados";
            return state.permisos;
        },
    },
    actions: {
        setUser(user){
            this.user = user;
            localStorage.setItem('user', encodeObj(user));            
        },
        setToken(token){
            this.token = token;
            localStorage.setItem('token', encode(token));
        },
        setRol(rol){
            this.rol = rol;
            localStorage.setItem('rol', encodeObj(rol));
        },
        setPermisos(permisos){
            this.permisos = permisos;
            localStorage.setItem('permisos', encodeObj(permisos));
        },
        setIsLogin(isLogin){
            this.isLogin = isLogin;
        },
        
        hasToken(){
            this.token = decode(localStorage.getItem('token'));
            return !!this.token;
        },

        isTokenValid(){
            let tokenDecode = decodeJWT(this.token);
            if(tokenDecode == false){
                this.$reset();
                return false;
            }
            
            if(isTokenExpire(tokenDecode.exp)){
                this.isLogin = false;
                this.$reset();
                return false;
            } else {                
                return true;
            }
        },
        
        // async login(usuario, password){
        //     try {
        //         this.isLoading = true;
        //         const response = await AuthService.login(usuario, password);
        //         if(response.data.transaccion){
        //             this.setUser(response.data.data.usuario);
        //             this.setToken(response.data.data.authorization.token);
        //             this.isLogin = true;
        //         }else{
        //             this.error = response.data.mensaje;
        //         }
        //     } catch (error) {
        //         this.isLogin = false;
        //         this.error = error.message;
        //     } finally {
        //         this.isLoading = false;
        //     }
        // },

        logout(){
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('permisos');
            localStorage.removeItem('rol');
            this.isLogin = false;
            this.$reset();
            setTimeout(() => router.push('/login'), 500);
        },

        logoutFormInscripcion(){
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('permisos');
            localStorage.removeItem('rol');
            this.isLogin = false;
            this.$reset();
            window.location.reload(true);
            // router.go(0);
        }

    }
})