<script>
import { useAuthStore } from "../stores/authStore";
export default {
    data(){
        return{
            permisos: useAuthStore().getPermisos,
        }
    },
    methods:{
        hascan(permiso){
            if(permiso)
                return this.permisos.includes(permiso);
            else
                return true;
        },
        isTokenValid(){
            return useAuthStore().isTokenValid();
        },
    },
};
</script>