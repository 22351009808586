<template>
    <div>
        <Dialog id="modalVisor" header="Recuperar Contraseña" :modal="true" :visible="visibleModal" :position="position" :style="{width: '400px'}">
            <template #header>
                <div class="flex align-items-center w-full">
                    <h5>Recuperar Contraseña</h5>
                    <Button @click="cerrarModal" icon="pi pi-times" class="p-button-rounded p-button-outlined ml-auto"/>
                </div>
            </template>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <label for="correo">Correo</label>
                    <InputText v-model="correo" id="correo" type="text" @input="validateField('correo')"/>
                    <small v-if="fieldErrors?.correo" class="p-error" >{{ fieldErrors.correo }}</small>
                </div>						
            </div>
            <template #footer>
                <Button label="Cancelar" @click="cerrarModal" class="p-button-outlined p-button-primary mr-2"/>
                <Button label="Solicitar recuperación" class="p-button-primary" @click="solicitarRecuperacion" :loading="isLoading"/>
            </template>
        </Dialog>        
    </div>
</template>
<script>
	import { EstudianteService } from '../../../service/EstudianteService';
	import { recuperacionSchema } from "../validations/recuperacionValidate";
    
	export default {
        name: 'ModalRecuperarPassword',
        props: {            
            visibleModal: {
                type: Boolean,
                default: false
            },          
            
        },
        components: {

        },
		data() {
			return {	
                isLoading: false,
                
				position: 'center',
				visibleLeft: false,
				visibleRight: false,
				visibleTop: false,
				visibleBottom: false,
				visibleFull: false,		
                
                correo: null,
                erroresDatos: null,

                fieldErrors: [],
			}
		},
		
		created() {
			
		},
		mounted() {
            
		},
        computed:{
            
        },
		methods: {
			cerrarModal() {	
                this.$emit('close');
                this.fieldErrors = [];
                this.correo = null;
			},
            async validateField(field) {
				const recuperacionData = {
					correo: this.correo,
				};
				try {
					await recuperacionSchema.validateAt(field, recuperacionData);
					this.fieldErrors[field] = '';
				} catch (error) {
					this.fieldErrors[field] = error.message;
				}
			},
            async validateRecuperacion(){
				let errorValidacion = false;
				const recuperacionData = {
					correo: this.correo,					
				};
				await recuperacionSchema
							.validate(recuperacionData, { abortEarly: false })
							.then(() => {
								errorValidacion = false;
								this.fieldErrors = [];
							})
							.catch((errors) => {
								const formattedErrors = [];
								errors.inner.forEach((error) => {
									formattedErrors[error.path] = error.message;
								});
								this.fieldErrors = formattedErrors;
								errorValidacion = true;
							});
				return errorValidacion;
			},

            async solicitarRecuperacion() {
				this.isLoading = true;	
				const errorValidacion = await this.validateRecuperacion();			
				if(!errorValidacion){
					try {					
						const response = await EstudianteService.solicitarRecuperacion({email: this.correo});
						console.log(response.data)			

						if(response.data?.transaccion){
							this.$toast.add({severity:'success', summary: 'Mensaje', detail:'Iniciando sesión...', life: 3000});
							this.$router.push('/');
						}
						
					} catch (error) {
						console.log(error.response);
						if(error.response?.data.message){
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${error.response?.data.message}`, life: 3000});				
						}else{
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: `Ocurrió un error al conectarse con el servidor. ${error.message}`, life: 3000});				
						}
						this.isLoading = false;
					}
				}
				
				this.isLoading = false;				
			},
		}
	}
</script>

<style>
    #modalVisor .p-dialog-header-close{
        display: none !important;
        visibility: hidden !important;
    }
    .pdf-viewer{
        height: 70vh;
    }
</style>