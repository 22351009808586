<template>
	<div class="topbar clearfix">
		<div class="topbar-left">
			<router-link to="/">
				<button class="p-link">
					<img alt="CEPI" src="/layout/images/cepi/logo-cepi-web.png" class="topbar-logo"/>
				</button>
			</router-link>
		</div>

		<div class="topbar-right">
			<button class="p-link" id="menu-button" @click="onMenuButtonClick">
				<i class="pi pi-angle-left"></i>
			</button>

			<button class="p-link" id="topbar-menu-button" @click="onTopbarMenuButtonClick">
				<i class="pi pi-bars"></i>
			</button>
			<ul :class="topbarItemsClass">
				<li v-if="profileMode === 'top' || horizontal"
					:class="['profile-item', {'active-top-menu': activeTopbarItem === 'profile'}]"
					@click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
					<button class="p-link">
						<img alt="User" class="profile-image" src="/layout/images/gadch/user-m.jpg"/>
                        <div>
                            <span class="topbar-item-name">{{ authStore.getUser?.nombres }} {{ authStore.getUser?.paterno }} {{ authStore.getUser?.materno }}</span>
                            <span class="topbar-item-role">{{ authStore.getUser?.email }}</span>
                        </div>
					</button>

					<transition name="layout-submenu-container">
						<ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'profile'">	
							<li role="menuitem">
								<button class="p-link topbar-item-button" @click="miPerfil()">
									<i class="pi pi-fw pi-user"></i>
									<span>Mi Perfil</span>
								</button>
							</li>						
							<li role="menuitem">
								<button class="p-link topbar-item-button" @click="logout">
									<i class="pi pi-fw pi-sign-out"></i>
									<span>Salir</span>
								</button>
							</li>
						</ul>
					</transition>
				</li>
				
			</ul>
		</div>
	</div>
</template>

<script>
	import { useAuthStore } from './stores/authStore';

	export default {
		props: {
			topbarMenuActive: Boolean,
			profileMode: String,
			horizontal: Boolean,
			activeTopbarItem: String
		},
		setup() {
			const authStore = useAuthStore();			
			return {
				authStore
			}
		},
		methods: {
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			logout(){
				this.authStore.logout();
			},
			miPerfil(){
				this.$router.push({ name: 'perfil'});
			}
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			}
		},
		mounted() {
			// console.log(this.authStore.getUser.name);
		},
	}
</script>
