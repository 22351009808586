import * as yup from 'yup';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const loginSchema = yup.object().shape({
    // correo: yup.string().email('Escriba un correo válido').required('El correo es obligatorio'),
    correo: yup.string().matches(emailRegex, 'Escriba un correo válido').required('El correo es obligatorio'),
    sessionGoogle: yup.boolean(),
    password: yup.string().when('sessionGoogle', {
        is: false,
        then: (schema) => schema.min(6, 'La contraseña debe contener 6 caracteres como mínimo').required('Escriba la contraseña'),
        otherwise: (schema) => schema.min(0),
    }),
});