<template>
	<div>
		<div :class="['profile', {'profile-expanded': expanded}]">
			<button class="p-link" @click="onClick">
				<img alt="Profile" class="profile-image" src="layout/images/avatar.png"/>
                <div>
                    <span class="profile-name">Isabel Oliviera</span>
                    <i class="pi pi-fw pi-chevron-down"></i>
                    <span class="profile-role">Marketing</span>
                </div>
			</button>
		</div>

		<transition name="layout-profile-menu">
			<ul class="layout-menu profile-menu" v-show="expanded">
				<li role="menuitem">
					<a href="#" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-user"></i>
						<span>Profile</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Profile</div>
					</div>
				</li>
				<li role="menuitem">
					<a href="#" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-lock"></i>
						<span>Privacy</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Privacy</div>
					</div>
				</li>
				<li role="menuitem">
					<a href="#" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-cog"></i>
						<span>Settings</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Settings</div>
					</div>
				</li>
				<li role="menuitem">
					<a href="#" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-sign-out"></i>
						<span>Logout</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Logout</div>
					</div>
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
export default {
	emits: ['profile-click'],
	props: {
		expanded: {
			type: Boolean,
			default: null
		}
	},
	methods: {
		onClick(event) {
			this.$emit('profile-click', event);
			event.preventDefault();
		}
	},
	name: "AppInlineProfile"
}
</script>

<style scoped>

</style>
