<template>
	<div :class="containerClass" @click="onDocumentClick">
		<div class="layout-wrapper-content">
			<AppTopBar :topbarMenuActive="topbarMenuActive" :profileMode="profileMode" :horizontal="layoutMode === 'horizontal'" :activeTopbarItem="activeTopbarItem"
			@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick"></AppTopBar>

			<transition name="layout-menu-container">
				<div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
					<div class="menu-scroll-content">
						<AppInlineProfile v-if="profileMode === 'inline' && layoutMode !== 'horizontal'" @profile-click="onProfileClick" :expanded="profileExpanded"></AppInlineProfile>

						<AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>

					</div>
				</div>
			</transition>
			
			<div class="layout-main">
				<router-view />
			</div>

			<!-- <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :darkMenu="darkMenu" @menu-color-change="onMenuColorChange"
						:profileMode="profileMode" @profile-mode-change="onProfileModeChange" :layout="layoutColor" :layouts="layoutColors" @layout-color-change="onLayoutColorChange"
						:theme="theme" :themes="themeColors" @theme-change="onThemeChange"  :layoutScheme='layoutScheme' @layout-scheme-change='changeLayoutScheme'></AppConfig> -->

			<div class="layout-mask"></div>

			<AppFooter />
		</div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
// import AppConfig from './AppConfig.vue';
import AppInlineProfile from './AppInlineProfile.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';

export default {
    props: {
        theme: String,
        layoutColor: String,
        layoutScheme: String,
        darkMenu: Boolean,
    },
    data() {
        return {
			layoutMode: 'static',
			profileMode: 'top',
			overlayMenuActive: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			rotateMenuButton: false,
			menu : [
				{
					label: 'Menu', icon: 'pi pi-fw pi-home',
					items: [
						{
							label: 'Panel de Control', icon: 'pi pi-fw pi-desktop', to: '/'
						},
						

						{	
							label: 'Programas Ofertados', icon: 'pi pi-fw pi-list', to: '/programas/lista'
						},
						
						{	
							label: 'Mis Inscripciones', icon: 'pi pi-fw pi-database', to: '/inscripciones/lista'
						},

						{
							label: 'Pagos', icon: 'pi pi-fw pi-inbox', to: '/pagos/lista'
						},
						
						{
							label: 'Pagos del Cursante por Programa', icon: 'pi pi-fw pi-file-export', to: '/pagos/kardex-programa'
						},

						// {
						// 	label: 'No Adeudo', icon: 'pi pi-fw pi-file-export', to: '/pagos/no-adeudo'
						// },
					]
				},
				
				
			],
			
			menuActive: false,
			profileExpanded: false,
			themeColors: [
                {name: 'Blue', file: 'blue', color: '#18318B'},
                {name: 'Indigo', file: 'indigo', color: '#6610f2'},
                {name: 'Purple', file: 'purple', color: '#6f42c1'},
                {name: 'Pink', file: 'pink', color: '#d63384'},
                {name: 'Orange', file: 'orange', color: '#fd7e14'},
                {name: 'Yellow', file: 'yellow', color: '#ffc107'},
                {name: 'Green', file: 'green', color: '#198754'},
                {name: 'Teal', file: 'teal', color: '#20c997'},
                {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
			],
			layoutColors: [
                {name: 'Blue', file: 'blue', color: '#0d6efd'},
                {name: 'Indigo', file: 'indigo', color: '#6610f2'},
                {name: 'Purple', file: 'purple', color: '#6f42c1'},
                {name: 'Pink', file: 'pink', color: '#d63384'},
                {name: 'Orange', file: 'orange', color: '#fd7e14'},
                {name: 'Yellow', file: 'yellow', color: '#ffc107'},
                {name: 'Green', file: 'green', color: '#198754'},
                {name: 'Teal', file: 'teal', color: '#20c997'},
                {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
			],
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
		onDocumentClick() {
			if(!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if(!this.menuClick) {
				if(this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					EventBus.emit('reset-active-index');
				}

				this.hideOverlayMenu();
			}

			this.topbarItemClick = false;
			this.menuClick = false;
		},
		onProfileClick(event) {
			this.profileExpanded = !this.profileExpanded;
			if(this.isHorizontal() || this.isSlim()) {
				EventBus.emit('reset-active-index');
			}

			event.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if(!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if(!event.item.items && this.isHorizontal()) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;

			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if(this.layoutMode === 'overlay') {
				this.overlayMenuActive = !this.overlayMenuActive;
			}
			else {
				if(this.isDesktop())
					this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
				else
					this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if(this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isHorizontal() {
			return this.layoutMode === 'horizontal';
		},
		isSlim() {
			return this.layoutMode === 'slim';
		},
		isMenuVisible() {
			if (this.isDesktop()) {
				if (this.layoutMode === 'static')
					return !this.staticMenuDesktopInactive;
				else if (this.layoutMode === 'overlay')
					return this.overlayMenuActive;

				else
					return true;
			} else {
				if (this.staticMenuMobileActive)
					return true;
				else if (this.layoutMode === 'overlay')
					return this.overlayMenuActive;
				else
					return true;
			}
		},
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
			this.staticMenuDesktopInactive = false;
			this.overlayMenuActive = false;

			if (this.isHorizontal()) {
				this.profileMode = 'top';
				this.profileMenuActive = false;
			}
		},
		onMenuColorChange(menuColor) {
            this.$emit('menu-color-change', menuColor);
		},
        changeLayoutScheme(scheme) {
            this.$emit('layout-scheme-change', scheme);
        },
		onProfileModeChange(profileMode) {
			this.profileMode = profileMode;
		},
		onLayoutColorChange(layout) {
            this.$emit('layout-change', layout);
		},
		onThemeChange(theme) {
            this.$emit('theme-change', theme);
		}
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
				'menu-layout-static': this.layoutMode !== 'overlay',
				'menu-layout-overlay': this.layoutMode === 'overlay',
				'layout-menu-overlay-active': this.overlayMenuActive,
				'menu-layout-slim': this.layoutMode === 'slim',
				'menu-layout-horizontal': this.layoutMode === 'horizontal',
				'layout-menu-static-inactive': this.staticMenuDesktopInactive,
				'layout-menu-static-active': this.staticMenuMobileActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
        },
		menuClass() {
			return ['layout-menu-container', {'layout-menu-dark': this.darkMenu}];
		}
    },
    components: {
        'AppTopBar': AppTopBar,
        // 'AppConfig': AppConfig,
        'AppInlineProfile': AppInlineProfile,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
